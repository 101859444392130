import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import gsap from 'gsap';
import useOutside from 'react-useoutside-handler';
import { changeLocale, IntlContextConsumer, useIntl } from 'gatsby-plugin-intl';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonTheme, ButtonHilight, ButtonSlot, Container } from 'components';

import Modal from './modal';

import {
  State,
  Brand,
  BrandImage,
  BrandRouter,
  Burger,
  BurgerButton,
  BurgerLine,
  Nav,
  NavItem,
  NavLists,
  Request,
  RequestButton,
  Translate,
  TranslateButton,
  TranslateIcon,
  TranslateModal,
} from './styled';

const languageFlag = {
  en: 'flag-en--fulid.png',
  th: 'flag-th--fulid.png',
};

const Header = (props) => {
  const { pathname } = props;
  const [stateModalCasino, setStateModalCasino] = useState(false);
  const [stateModalSlot, setStateModalSlot] = useState(false);
  const [stateModalSport, setStateModalSport] = useState(false);
  const [stateModalEsport, setStateModalEsport] = useState(false);
  const [stateModalType, setStateModalType] = useState('');
  const burger = useSelector((state) => state.userInterfacWhitelist.burger);
  const dispatch = useDispatch();
  const intl = useIntl();
  const refBurgerLine1 = useRef(null);
  const refBurgerLine2 = useRef(null);
  const refBurgerLine3 = useRef(null);
  const refBurgerLine4 = useRef(null);
  const refTranslateModal = useRef(null);
  const refTranslateState = useRef(null);
  const translate = useSelector((state) => state.userInterface.translate);
  const { locale } = useIntl();

  const handleTranslateToggle = () => {
    if (translate === false) {
      dispatch({ type: 'SET_TRANSLATE_OPEN' });
    }
  };

  const handleBurgerToggle = () => {
    if (burger === false) {
      document.body.style.overflow = 'hidden';

      dispatch({ type: 'SET_MODAL_OPEN' });

      gsap.set([refBurgerLine1.current, refBurgerLine4.current], {
        duration: 0.25,
        opacity: 0,

        onComplete() {
          gsap.to(refBurgerLine2.current, {
            duration: 0.25,
            rotation: 45,
          });

          gsap.to(refBurgerLine3.current, {
            duration: 0.25,
            rotation: -45,
          });
        },
      });
    }

    if (burger === true) {
      dispatch({ type: 'SET_MODAL_CLOSE' });

      gsap.to([refBurgerLine2.current, refBurgerLine3.current], {
        duration: 0.25,
        rotation: 0,

        onComplete() {
          gsap.to(refBurgerLine4.current, {
            duration: 0.25,
            opacity: 1,
          });

          gsap.to([refBurgerLine1.current, refBurgerLine4.current], {
            duration: 0.25,
            opacity: 1,

            onComplete() {
              document.body.removeAttribute('style');
            },
          });
        },
      });
    }
  };

  const handleCasinoOver = () => {
    if (pathname !== ('/th/casinos-online' || '/en/casinos-online')) {
      setStateModalCasino(true);
      setStateModalType('casino');
    }
  };

  const handleCasinoOut = () => {
    setStateModalCasino(false);
    setStateModalType('');
  };

  const handleSlotOver = () => {
    if (pathname !== ('/th/slots' || '/en/slots')) {
      setStateModalSlot(true);
      setStateModalType('slot');
    }
  };

  const handleSlotOut = () => {
    setStateModalSlot(false);
    setStateModalType('');
  };

  const handleSportOver = () => {
    if (pathname !== ('/th/sports' || '/en/sports')) {
      setStateModalSport(true);
      setStateModalType('sport');
    }
  };

  const handleSportOut = () => {
    setStateModalSport(false);
    setStateModalType('');
  };

  const handleEsportOver = () => {
    if (pathname !== ('/th/e-sports' || '/en/e-sports')) {
      setStateModalEsport(true);
      setStateModalType('esport');
    }
  };

  const handleEsportOut = () => {
    setStateModalEsport(false);
    setStateModalType('');
  };

  useOutside(refTranslateModal, () =>
    dispatch({ type: 'SET_TRANSLATE_CLOSE' })
  );
  useOutside(refTranslateState, () =>
    dispatch({ type: 'SET_TRANSLATE_CLOSE' })
  );

  return (
    <State>
      <Container>
        <Brand>
          <BrandRouter to="/">
            {!isMobile && (
              <>
                <BrandImage
                  source="alltopasia-brand--desktop.png"
                  alt="ALLTOP ASIA"
                  desktop
                />

                <BrandImage
                  source="alltopasia-brand--mobile.png"
                  alt="ALLTOP ASIA"
                  mobile
                />
              </>
            )}

            {isMobile && (
              <BrandImage
                source="alltopasia-brand--mobile.png"
                alt="ALLTOP ASIA"
                mobile
              />
            )}
          </BrandRouter>
        </Brand>

        <Nav>
          <NavLists>
            <NavItem
              onMouseEnter={handleCasinoOver}
              onMouseLeave={handleCasinoOut}
              item1
            >
              <ButtonSlot
                icon="casino"
                title={intl.formatMessage({ id: 'global.navigation.casinos' })}
                to="/casinos-online"
              />
              <Modal render={stateModalCasino} type={stateModalType} />
            </NavItem>

            <NavItem
              onMouseEnter={handleSlotOver}
              onMouseLeave={handleSlotOut}
              item2
            >
              <ButtonSlot
                icon="slot"
                title={intl.formatMessage({ id: 'global.navigation.slots' })}
                to="/slots"
              />
              <Modal render={stateModalSlot} type={stateModalType} />
            </NavItem>

            <NavItem
              onMouseEnter={handleSportOver}
              onMouseLeave={handleSportOut}
              item3
            >
              <ButtonSlot
                icon="sport"
                title={intl.formatMessage({ id: 'global.navigation.sports' })}
                to="/sports"
              />
              <Modal render={stateModalSport} type={stateModalType} />
            </NavItem>

            <NavItem
              onMouseEnter={handleEsportOver}
              onMouseLeave={handleEsportOut}
              item4
            >
              <ButtonSlot
                icon="esport"
                title={intl.formatMessage({ id: 'global.navigation.esports' })}
                to="/e-sports"
              />
              <Modal render={stateModalEsport} type={stateModalType} />
            </NavItem>

            <NavItem item5>
              <ButtonSlot
                icon="promotion"
                title={intl.formatMessage({
                  id: 'global.navigation.promotions',
                })}
                to="/promotions"
              />
            </NavItem>

            <NavItem item6>
              <ButtonSlot
                icon="reward"
                title={intl.formatMessage({ id: 'global.navigation.reward' })}
                blank="https://www.alltoppoint.com/"
              />
            </NavItem>

            {/*
            <NavItem item7>
              <ButtonSlot
                icon="event"
                title={intl.formatMessage({ id: 'global.navigation.events' })}
                to="/"
              />
            </NavItem>
            */}

            {!isMobile && (
              <NavItem item8>
                <ButtonHilight
                  icon="partner"
                  title={intl.formatMessage({
                    id: 'global.navigation.affiliate',
                  })}
                  blank="/affiliate"
                />
              </NavItem>
            )}
          </NavLists>
        </Nav>

        <Request>
          <RequestButton first>
            <ButtonTheme
              icon="signin"
              title={intl.formatMessage({ id: 'header.request.signin' })}
              blank="https://app.alltopasia2.com/login/"
              type="silver"
            />
          </RequestButton>

          <RequestButton last>
            <ButtonTheme
              icon="register"
              title={intl.formatMessage({ id: 'header.request.register' })}
              blank="https://app.alltopasia2.com/register/"
              type="gold"
            />
          </RequestButton>
        </Request>

        <Translate ref={refTranslateState}>
          <TranslateButton onClick={handleTranslateToggle} type="button">
            {locale === 'th' ? (
              <TranslateIcon source="flag-th--fulid.png" alt="ไทย" />
            ) : (
              <TranslateIcon source="flag-en--fulid.png" alt="English" />
            )}
          </TranslateButton>

          {translate && (
            <TranslateModal ref={refTranslateModal}>
              <IntlContextConsumer>
                {({ languages, language: currentLocale }) =>
                  languages
                    .filter((language) => currentLocale !== language)
                    .map((language) => (
                      <TranslateButton
                        key={language}
                        onClick={() => {
                          changeLocale(language);
                          dispatch({ type: 'SET_TRANSLATE_CLOSE' });
                        }}
                        type="button"
                      >
                        <TranslateIcon
                          source={languageFlag[language]}
                          alt="Language"
                        />
                      </TranslateButton>
                    ))
                }
              </IntlContextConsumer>
            </TranslateModal>
          )}
        </Translate>

        <Burger>
          <BurgerButton onClick={handleBurgerToggle}>
            <BurgerLine ref={refBurgerLine1} line1 />
            <BurgerLine ref={refBurgerLine2} line2 />
            <BurgerLine ref={refBurgerLine3} line3 />
            <BurgerLine ref={refBurgerLine4} line4 />
          </BurgerButton>
        </Burger>
      </Container>
    </State>
  );
};

Header.propTypes = {
  pathname: PropTypes.string.isRequired,
};

export default Header;
