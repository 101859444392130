import React from 'react';
import { Iconsvg } from 'utils';
import { useIntl } from 'gatsby-plugin-intl';
import { State, MenuBlank, MenuLink, MenuIcon, MenuTitle } from './styled';

const Navbar = () => {
  const intl = useIntl();

  return (
    <State>
      <MenuBlank
        href="https://app.alltopasia2.com/register"
        target="_blank"
        rel="noopener noreferrer"
      >
        <MenuIcon>
          <Iconsvg icon="register" />
        </MenuIcon>

        <MenuTitle>
          {intl.formatMessage({ id: 'navbar.menu.register' })}
        </MenuTitle>
      </MenuBlank>

      <MenuBlank
        href="https://app.alltopasia2.com/login"
        target="_blank"
        rel="noopener noreferrer"
      >
        <MenuIcon>
          <Iconsvg icon="signin" />
        </MenuIcon>

        <MenuTitle>
          {intl.formatMessage({ id: 'navbar.menu.signin' })}
        </MenuTitle>
      </MenuBlank>

      <MenuLink to="/">
        <MenuIcon center>
          <Iconsvg icon="lobby" />
        </MenuIcon>

        <MenuTitle center>
          {intl.formatMessage({ id: 'navbar.menu.lobby' })}
        </MenuTitle>
      </MenuLink>

      <MenuLink to="/promotions">
        <MenuIcon>
          <Iconsvg icon="promotion" />
        </MenuIcon>

        <MenuTitle>
          {intl.formatMessage({ id: 'navbar.menu.promotion' })}
        </MenuTitle>
      </MenuLink>

      <MenuBlank
        href="https://alltopasia.com/th/affiliate/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <MenuIcon>
          <Iconsvg icon="partner" />
        </MenuIcon>

        <MenuTitle>
          {intl.formatMessage({ id: 'navbar.menu.partner' })}
        </MenuTitle>
      </MenuBlank>
    </State>
  );
};
export default Navbar;
